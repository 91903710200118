import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

interface HACatalogState {
    showHACatalogOverlay: boolean;
}

const initialState: HACatalogState = {
    showHACatalogOverlay: false,
};

const simulationModeReducer = createReducer<HACatalogState>(initialState, builder =>
    builder
        .addCase(actions.openHACatalogOverlay, state => ({
            ...state,
            showHACatalogOverlay: true,
        }))
        .addCase(actions.closeHACatalogOverlay, state => ({
            ...state,
            showHACatalogOverlay: false,
        }))
);

export default simulationModeReducer;
